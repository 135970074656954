$(function () {
    $("body").on("form:saved", function (e, data) {
        if (data.hasOwnProperty('task') && data.hasOwnProperty('status')) {
            let msgText;
            if (data.status === 'complete') {
                msgText = "Thank you for submitting your " + data.task +
                    " for your upcoming trip!";
            } else {
                msgText = "You have successfully saved a draft of your " + data.task +
                    ". Don&apos;t forget to come back and complete the form as soon as possible.";
            }
            const msgHtml = `
            <p>${msgText}</p>
            <p>If you have any questions or concerns please be sure to reach out to your wildlife safari coordinator
            so they can help you.</p>
            In the meantime,
            <ul>
            <li>Introduce yourself on the Cheeseman&apos; Trip Facebook Group&ndash;click on <strong>Be Social With Us</strong></li>
            <li>Subcribe to the <a href="https://www.youtube.com/user/CheesemansEcology" target="_blank">
            Cheeseman&apos;s YouTube Channel</a> to learn more about the destination
            as well as others we take trips to!</li>
            </ul>
            <p>It&apos;s time to start getting excited about your trip!</p>
            <p>Cheers!<br>Cheesemans&apos; Ecology Safaris Staff</p>`;
            $('#message-text').html(msgHtml);
            $('#message').addClass('alert-success').show("slow");
        }
    });
});
